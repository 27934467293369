var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-bottom":"50px"},attrs:{"id":"contact-page"}},[_vm._m(0),_c('section',{staticClass:"contact-style-2 pt-30 pb-35"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"contact2-title text-center mb-4"},[_c('h2',[_vm._v("contact us")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.settings.desc)}})])])])])]),_c('div',{staticClass:"contact-two-area pt-60 pb-70"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"contact-message"},[_c('form',{staticClass:"contact-form",attrs:{"id":"contact-form","method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit($event)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.email.$model),expression:"$v.email.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                      'is-invalid': _vm.$v.email.$error,
                      'is-valid': !_vm.$v.email.$invalid
                    },attrs:{"type":"email","placeholder":"E-mail"},domProps:{"value":(_vm.$v.email.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.email, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._m(1),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.email.required)?_c('span',[_vm._v("• Email is required")]):_vm._e(),(!_vm.$v.email.inUnique)?_c('span',[_vm._v("• This Email is Wrong")]):_vm._e()])]),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.subject.$model),expression:"$v.subject.$model",modifiers:{"trim":true}}],staticClass:"form-control selc-valid2",class:{
                      'is-invalid': _vm.$v.subject.$error,
                      'is-valid': !_vm.$v.subject.$invalid
                    },attrs:{"type":"text","placeholder":"Subject"},domProps:{"value":(_vm.$v.subject.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.subject, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._m(2),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.subject.required)?_c('span',[_vm._v("• Subject is required.")]):_vm._e(),(!_vm.$v.subject.minLength)?_c('span',[_vm._v(" • Subject must have at least "+_vm._s(_vm.$v.subject.$params.minLength.min)+" Letters ")]):_vm._e(),(!_vm.$v.subject.maxLength)?_c('span',[_vm._v(" • Subject must have at most "+_vm._s(_vm.$v.subject.$params.maxLength.max)+" Letters ")]):_vm._e()])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"contact2-textarea form-group mt-4"},[_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.message.$model),expression:"$v.message.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                        'is-invalid': _vm.$v.message.$error,
                        'is-valid': !_vm.$v.message.$invalid
                      },attrs:{"placeholder":"Message *","name":"message","id":"exampleFormControlTextarea1","rows":"9"},domProps:{"value":(_vm.$v.message.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.message, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._m(3),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.message.required)?_c('span',[_vm._v("• Message is required.")]):_vm._e(),(!_vm.$v.message.minLength)?_c('span',[_vm._v(" • Message must have at least "+_vm._s(_vm.$v.message.$params.minLength.min)+" Letters ")]):_vm._e(),(!_vm.$v.subject.maxLength)?_c('span',[_vm._v(" • Message must have at most "+_vm._s(_vm.$v.message.$params.maxLength.max)+" Letters ")]):_vm._e()])]),_vm._m(4)]),_vm._m(5)])])])])])])]),_c('notifications',{attrs:{"group":"foo"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"breadcrumb-area mb-30"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"breadcrumb-wrap"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ul',{staticClass:"breadcrumb"})])])])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"valid-feedback"},[_c('span',[_vm._v("• Your Email is valid")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"valid-feedback"},[_c('span',[_vm._v("Subject is correct")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"valid-feedback"},[_c('span',[_vm._v("Message is correct")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-btn text-center"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"submit"}},[_vm._v(" Send Message ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('p',{staticClass:"form-messege"})])}]

export { render, staticRenderFns }