<template>
  <div id="contact-page" style="margin-bottom: 50px">
    <!-- breadcrumb area start -->
    <div class="breadcrumb-area mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ul class="breadcrumb">

                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- breadcrumb area end -->

    <!-- contact us area -->
    <section class="contact-style-2 pt-30 pb-35">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="contact2-title text-center mb-4">
              <h2>contact us</h2>
              <!-- <p>{{settings.desc}}</p> -->
              <div v-html="settings.desc"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- contact form two -->
    <div class="contact-two-area pt-60 pb-70">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="contact-message">
              <form
                id="contact-form"
                @submit.prevent="formSubmit"
                method="post"
                class="contact-form"
              >
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="E-mail"
                      v-model.trim="$v.email.$model"
                      :class="{
                        'is-invalid': $v.email.$error,
                        'is-valid': !$v.email.$invalid
                      }"
                    />
                    <div class="valid-feedback">
                      <span>• Your Email is valid</span>
                    </div>
                    <div class="invalid-feedback">
                      <span v-if="!$v.email.required">• Email is required</span>
                      <span v-if="!$v.email.inUnique"
                        >• This Email is Wrong</span
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <input
                      type="text"
                      class="form-control selc-valid2"
                      placeholder="Subject"
                      v-model.trim="$v.subject.$model"
                      :class="{
                        'is-invalid': $v.subject.$error,
                        'is-valid': !$v.subject.$invalid
                      }"
                    />
                    <div class="valid-feedback">
                      <span>Subject is correct</span>
                    </div>
                    <div class="invalid-feedback">
                      <span v-if="!$v.subject.required"
                        >• Subject is required.</span
                      >
                      <span v-if="!$v.subject.minLength">
                        • Subject must have at least
                        {{ $v.subject.$params.minLength.min }} Letters
                      </span>
                      <span v-if="!$v.subject.maxLength">
                        • Subject must have at most
                        {{ $v.subject.$params.maxLength.max }} Letters
                      </span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="contact2-textarea form-group mt-4">
                      <textarea
                        placeholder="Message *"
                        name="message"
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="9"
                        v-model.trim="$v.message.$model"
                        :class="{
                          'is-invalid': $v.message.$error,
                          'is-valid': !$v.message.$invalid
                        }"
                      ></textarea>
                      <div class="valid-feedback">
                        <span>Message is correct</span>
                      </div>
                      <div class="invalid-feedback">
                        <span v-if="!$v.message.required"
                          >• Message is required.</span
                        >
                        <span v-if="!$v.message.minLength">
                          • Message must have at least
                          {{ $v.message.$params.minLength.min }} Letters
                        </span>
                        <span v-if="!$v.subject.maxLength">
                          • Message must have at most
                          {{ $v.message.$params.maxLength.max }} Letters
                        </span>
                      </div>
                    </div>
                    <div class="contact-btn text-center">
                      <button class="btn btn-secondary" type="submit">
                        Send Message
                      </button>
                    </div>
                  </div>
                  <div class="col-12 d-flex justify-content-center">
                    <p class="form-messege"></p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <notifications group="foo" />
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email
} from "vuelidate/lib/validators";
export default {
  name: "Contact",
  mounted() {
   window.scrollTo(0, 0)
    this.getSetting();
  },
  data() {
    return {
      email: "",
      subject: "",
      message: "",
      output: "",
      stat: "",
      countform: 0,
      settings: []
    };
  },
  validations: {
    subject: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(40)
    },
    message: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(300)
    },
    email: {
      required,
      email,
      inUnique(value) {
        if (value === "") return true;

        var email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

        return new Promise(resolve => {
          setTimeout(() => {
            resolve(email_regex.test(value));
          }, 350 + Math.random() * 300);
        });
      }
    }
  },
  methods: {
    formSubmit(e) {
      if (this.countform < 2) {
        this.$v.$touch();
        this.countform++;
        if (this.$v.$invalid) {
          this.$submitstatus = "fail";
        } else {
          e.preventDefault();
          let currentObj = this;
          this.$http
            .post(
              "message?email=" +
                this.email +
                "&subject=" +
                this.subject +
                "&message=" +
                this.message
            )
            .then(function(response) {
              currentObj.output = response.data;
              currentObj.output = "Your request has been sent successfully";
              currentObj.stat = "success";
            })
            .catch(function(error) {
              currentObj.stat = "error";
              // currentObj.output = error;
              currentObj.output = "This mail is already subscribed OR " + error;
              // console.log("faild");
            });
          this.$notify({
            group: "foo",
            type: this.stat,
            title: "Important message",
            text: this.output
          });
        }
      } else {
        this.$notify({
          group: "foo",
          type: "error",
          title: "Wrong message",
          text: "Please stop sending, your request has already been sent"
        });
      }
    }
    // getSetting() {
    //   this.loading = true;
    //   this.$http
    //     .get("settings")
    //     .then(response => {
    //       this.settings = response.data.data;
    //       response => (this.settings = response.data.data);
    //     })
    //     .catch(error => console.log(error))
    //     .finally(() => (this.loading = false));
    // }
  }
};
</script>
